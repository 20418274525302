import styles from './Testimonial.module.scss'

import Card from '@components/card/Card'
import Image, { StaticImageData } from 'next/image'

export interface TestimonialProps {
  firstName: string
  lastName: string
  title: string
  company: string
  content: string
  headshotImageSource: StaticImageData
  companyLogo: string
}
export const Testimonial = (props: TestimonialProps) => {
  const { firstName, lastName, company, title, content, headshotImageSource } = props
  return (
    <Card>
      <div className="flexBoxRow" style={{ width: '300px' }}>
        <div className={`flexBoxCol margin-left-05`}>
          <div className="flexBoxRow">
            <div className={styles.headshot}>
              <Image src={headshotImageSource} />
            </div>
            <div className={styles.personInfo}>
              <div className="subheader">
                {firstName} {lastName}
              </div>
              <div className="textSmall bold">{company}</div>
              <div className="textSmall">{title}</div>
            </div>
          </div>

          <div
            className="margin-top-1"
            style={{ maxWidth: '30em', letterSpacing: '0.025em' }}
          >
            <div>{content}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

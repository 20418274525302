// Styles
import styles from './Card.module.scss'

const Card = ({
  children,
  ref = undefined,
  className = '',
  customStyles = {},
  onClick = () => {},
}) => {
  let classes = className ? `${styles.card} ${className}` : styles.card

  return (
    <div className={classes} ref={ref} style={customStyles} onClick={onClick}>
      {children}
    </div>
  )
}

export default Card

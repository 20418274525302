import styles from './InteractiveOpportunityManagementSection.module.scss'
import Image, { StaticImageData } from 'next/image'
import SummaryImage from '@public/images/lp/opportunity_summary.webp'
import NotesImage from '@public/images/lp/opportunity_notes.webp'
import TeamImage from '@public/images/lp/opportunity_team.webp'
import UpdatesImage from '@public/images/lp/opportunity_updates.webp'
import ScoringImage from '@public/images/lp/opportunity_scoring.webp'
import DocumentsImage from '@public/images/lp/opportunity_documents.webp'
import { useState } from 'react'
import Button from '@components/button/Button'

const SECTION_NAMES = ['Summary', 'Notes', 'Team', 'Documents', 'Score', 'Updates'] as const

export type SectionNameType = (typeof SECTION_NAMES)[number]

interface SectionProps {
  description: string
  image: JSX.Element
}

const SECTION_SPEC: {
  [key in SectionNameType]: SectionProps
} = {
  Summary: {
    description:
      'Track key datapoints about your opportunity. NextStage syncs your data with Sam.gov, eBuy, GovWin, and more.',
    image: (
      <Image
        alt="Opportunity description Screenshot"
        quality={100}
        src={SummaryImage}
        placeholder="blur"
      />
    ),
  },
  Notes: {
    description:
      'Keep your team in sync on key updates. Link email threads to opportunities with unique email address.',
    image: (
      <Image
        alt="Opportunity description Screenshot"
        quality={100}
        src={NotesImage}
        placeholder="blur"
      />
    ),
  },
  Team: {
    description: 'Track government contacts, teammates, and competitors.',
    image: (
      <Image
        alt="Opportunity Team Screenshot"
        quality={100}
        src={TeamImage}
        placeholder="blur"
      />
    ),
  },
  Documents: {
    description:
      'SharePoint, Google Drive, and built-in cloud storage to manage your documents.',
    image: (
      <Image
        alt="Opportunity description Screenshot"
        quality={100}
        src={DocumentsImage}
        placeholder="blur"
      />
    ),
  },
  Score: {
    description:
      'Measure the quality of the opportunity. Definite your scoring criteria and score opportunities as they move through your pipeline.',
    image: (
      <Image
        alt="Opportunity description Screenshot"
        quality={100}
        src={ScoringImage}
        placeholder="blur"
      />
    ),
  },
  Updates: {
    description: 'Replace time-consuming capture decks with automated one-pagers.',
    image: (
      <Image
        alt="Opportunity Updates Screenshot"
        quality={100}
        src={UpdatesImage}
        placeholder="blur"
      />
    ),
  },
}

export const InteractiveOpportunityManagementSection = () => {
  const [section, setSection] = useState<SectionNameType>('Summary')

  const sectionProps = SECTION_SPEC[section]

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.description}>
          <h4 className={styles.name}>Opportunity Management</h4>
          <h3 className={styles.headline}>
            Execute capture without spreadsheets, slide decks, and manual data entry
          </h3>

          <div className={styles.selectorContainer}>
            <div>Explore</div>
            <div className={styles.selectors}>
              {SECTION_NAMES.map((name) => (
                <Button
                  key={`${name}`}
                  btnType={name == section ? 'blue' : undefined}
                  onClick={() => setSection(name)}
                >
                  {name}
                </Button>
              ))}
            </div>
          </div>

          <div className={styles.description}>{sectionProps.description}</div>
        </div>
        <div className={styles.sectionImage}>{sectionProps.image}</div>
      </div>
    </div>
  )
}
